import axios from 'axios'
import { navigate } from 'gatsby'
import React from 'react'
import { getIdToken, isAuthenticated, login } from '../utils/auth'

interface IBlogPostRouterProps {
  pageContext: {
    contentDigest: string;
  }
}

export default class BlogPostRouter extends React.Component<IBlogPostRouterProps, {}> {
  public componentDidMount() {
    if (!isAuthenticated()) {
      localStorage.setItem('redirectUri', window.location.pathname)
      login()
      return <p>Redirecting to login...</p>
    }

    const { contentDigest } = this.props.pageContext
    const pageHashUrl = `${process.env.BLOG_API_SERVER}/blog/pagehash`
    const authStr = 'Bearer '.concat(getIdToken() || '')

    axios
      .get(pageHashUrl + `?contentDigest=${contentDigest}`, { headers: { Authorization: authStr } })
      .then(response => {
        const hashStr = window.location.hash
        const pageHash = response.data
        navigate(`/private/${pageHash}/${hashStr}`, { replace: true })
      })
      .catch(error => {
        console.log('error ' + error)
      })
    return null
  }

  public render() {
    return null
  }
}
